<script setup>
const router = useRouter()
const localePath = useLocalePath()

const props = defineProps({
  event: {
    type: Object,
    default: () => {},
  },
})

const emit = defineEmits(['event-card-click'])

const onEventCardClick = () => {
  const slug = props.event.slug
  router.push(localePath({name: 'event-slug', params: {slug: slug}}))
  emit('event-card-click', props.event)
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-event-card.css';
</style>

<template>
  <nuxt-link
    :to="localePath({name: 'event-slug', params: {slug: event.slug}})"
    class="d-flex flex-row flex-md-column justify-content-center nu-event-card"
    @click.prevent.stop="onEventCardClick"
  >
    <div class="nu-event-card-image">
      <img :src="event.image_url" />
    </div>

    <div class="d-flex flex-column card-content justify-content-start">
      <div class="d-flex justify-content-between">
        <h6 v-if="event.date">
          {{ event.date }}
        </h6>
        <span
          class="d-none d-md-block"
          v-if="event.availability"
          >{{ event.availability }}</span
        >
      </div>
      <div class="d-flex flex-column justify-content-between text-start">
        <p
          class="event-name"
          v-if="event.name"
        >
          {{ event.name }}
        </p>
        <p
          class="event-location"
          v-if="event.venue_name"
        >
          {{ event.venue_name }}
        </p>
        <span
          class="d-block d-md-none"
          v-if="event.availability"
          >{{ event.availability }}</span
        >
      </div>
    </div>
  </nuxt-link>
</template>
